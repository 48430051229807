<template>
  <div>
    mobile index
  </div>
</template>

<script>
export default {
  name: 'mobile_index'
}
</script>

<style lang="scss" scoped>
</style>
